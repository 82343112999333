<span [ngSwitch]="fieldTypeValue" [class.clipboard-enabled]="clipboardEnabled" [class.p-2]="clipboardEnabled">
    <span
        *ngSwitchDefault=""
        [class.blurred-text]="isBlurred"
        [class.nocap]="!allowPosthog"
        [innerHtml]="text | obscure : isBlurred"></span>
    <span *ngSwitchCase="'url'">
        <a *ngIf="url" [href]="text" [ngClass]="{ nocap: !allowPosthog }">{{ text }}</a>
        <a
            *ngIf="queryParams && !routerLinkUrl"
            [ngClass]="{ nocap: !allowPosthog }"
            [routerLink]="[]"
            [queryParams]="queryParams"
            (click)="onClick($event)">
            {{ text }}
        </a>
        <a
            *ngIf="routerLinkUrl"
            [ngClass]="{ nocap: !allowPosthog }"
            [routerLink]="routerLinkUrl"
            [queryParams]="queryParams"
            (click)="onClick($event)">
            {{ text }}
        </a>
    </span>
    <span
        *ngSwitchCase="'mail'"
        class="email"
        (click)="onComposeMail($event)"
        (keypress.enter)="onComposeMail(null)"
        tabindex="0">
        <span *ngIf="canChat()">
            <a href="#" (click)="onChat($event)">
                <i class="bi bi-chat-left-text me-2"></i>
            </a>
        </span>
        <a href="mailto:{{ text }}" [ngClass]="{ nocap: !allowPosthog }" [innerHTML]="readableEmail"></a>
    </span>
    <span class="copy">
        <button
            class="btn btn-link btn-color-gray-500 btn-active-color-gray-800 ms-2 pt-0 pb-1"
            (click)="onCopyClick($event)"
            [cdkCopyToClipboard]="text | obscure : isBlurred">
            <i *ngIf="false" class="ki-duotone ki-copy fs-2" style="visibility: visible"></i>
            <div class="icons">
                <svg
                class=""
                width="24"
                height="24"
                viewBox="0 0 16 16"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                [style.stroke-dasharray]="50"
                [style.stroke-dashoffset]="copied() ? -50 : 0">
                <path d="M5.75 4.75H10.25V1.75H5.75V4.75Z" />
                <path
                    d="M3.25 2.88379C2.9511 3.05669 2.75 3.37987 2.75 3.75001V13.25C2.75 13.8023 3.19772 14.25 3.75 14.25H12.25C12.8023 14.25 13.25 13.8023 13.25 13.25V3.75001C13.25 3.37987 13.0489 3.05669 12.75 2.88379" />
            </svg>
            <svg
                class="copied"
                width="24"
                height="24"
                viewBox="0 0 16 16"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                [style.stroke-dasharray]="50"
                [style.stroke-dashoffset]="copied() ? 0 : -50">
                <path d="M13.25 4.75L6 12L2.75 8.75" />
            </svg>
            </div>
        </button>
    </span>
</span>
