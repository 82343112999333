<!--begin::Toolbar-->
<div class="d-flex flex-wrap flex-stack pb-6 pt-2">
    <!--begin::Title-->
    <div class="d-flex flex-wrap align-items-center my-1">
        <h3 class="fw-bolder me-5 my-1" [ngPlural]="itemsCount">
            <ng-template ngPluralCase="=0">No users</ng-template>
            <ng-template ngPluralCase="=1">1 User</ng-template>
            <ng-template ngPluralCase="other">{{ itemsCount }} users</ng-template>
        </h3>
        <span class="me-2">Sort by</span>
        <button class="btn btn-light" [matMenuTriggerFor]="sortMenu">{{ sortFields[sortField] }}</button>
        <mat-menu #sortMenu>
            <button
                mat-menu-item
                *ngFor="let field of sortFields | keyvalue"
                (click)="sortFieldChangeHandler(field.key)">
                {{ field.value }}
            </button>
        </mat-menu>
        <a
            href="#"
            class="btn btn-icon btn-light"
            tooltip="Sort order"
            (click)="sortOrderChangeHandler(sortOrder === sortOrderEnum.Asc ? sortOrderEnum.Desc : sortOrderEnum.Asc)"
            data-kt-menu-placement="bottom-start">
            <!--begin::Svg Icon | path: icons/duotune/arrows/arr072.svg-->
            <span class="svg-icon svg-icon-2" *ngIf="sortOrder === sortOrderEnum.Desc">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                        fill="currentColor"></path>
                </svg>
            </span>
            <!--end::Svg Icon-->
            <!--begin::Svg Icon | path: /Users/shuhratsaipov/www/keenthemes/products/core/html/src/media/icons/duotune/arrows/arr073.svg-->
            <span class="svg-icon svg-icon-muted svg-icon-2" *ngIf="sortOrder === sortOrderEnum.Asc">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.5657 11.3657L16.75 15.55C17.1642 15.9643 17.8358 15.9643 18.25 15.55C18.6642 15.1358 18.6642 14.4643 18.25 14.05L12.7071 8.50716C12.3166 8.11663 11.6834 8.11663 11.2929 8.50715L5.75 14.05C5.33579 14.4643 5.33579 15.1358 5.75 15.55C6.16421 15.9643 6.83579 15.9643 7.25 15.55L11.4343 11.3657C11.7467 11.0533 12.2533 11.0533 12.5657 11.3657Z"
                        fill="currentColor" />
                </svg>
            </span>
            <!--end::Svg Icon-->
        </a>
        <span class="ms-4 me-4">Group by</span>
        <button class="btn btn-light me-2" [matMenuTriggerFor]="groupByMenu">
            {{ groupByFields[groupByField] ?? 'Person' }}
        </button>
        <mat-menu #groupByMenu>
            <button mat-menu-item (click)="groupByChangeHandler(null)">Person</button>
            <button
                mat-menu-item
                *ngFor="let field of groupByFields | keyvalue"
                (click)="groupByChangeHandler(field.key)">
                {{ field.value }}
            </button>
        </mat-menu>
    </div>
    <!--end::Title-->
    <!--begin::Controls-->
    <div class="d-flex flex-wrap my-1">
        <div class="d-flex">
            <select *ngIf="false" name="sort" class="form-select form-select-sm form-select-solid w-150px">
                <option value="1">First name</option>
                <option value="2">Second name</option>
                <option value="3">Job Title</option>
                <option value="4">Email</option>
            </select>
        </div>
        <!--begin::Tab nav-->
        <ul class="nav nav-pills me-6 mb-2 mb-sm-0">
            <li class="nav-item m-0">
                <button
                    (click)="viewModeChange.emit(0)"
                    [ngClass]="{ active: viewMode === 0 }"
                    tooltip="Grid"
                    [delay]="500"
                    class="btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary me-3">
                    <!--begin::Svg Icon | path: icons/duotune/general/gen024.svg-->
                    <span class="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="5" y="5" width="5" height="5" rx="1" fill="#000000" />
                                <rect x="14" y="5" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
                                <rect x="5" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
                                <rect x="14" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3" />
                            </g>
                        </svg>
                    </span>
                    <!--end::Svg Icon-->
                </button>
            </li>
            <li class="nav-item m-0">
                <button
                    (click)="viewModeChange.emit(1)"
                    [ngClass]="{ active: viewMode === 1 }"
                    class="btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary me-3"
                    tooltip="Table"
                    [delay]="500">
                    <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
                    <span class="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                fill="black" />
                            <path
                                opacity="0.3"
                                d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                fill="black" />
                        </svg>
                    </span>
                    <!--end::Svg Icon-->
                </button>
            </li>
            <li class="nav-item m-0">
                <button
                    (click)="viewModeChange.emit(2)"
                    [ngClass]="{ active: viewMode === 2, 'me-3': !isCalendarHidden }"
                    class="btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary"
                    tooltip="Faces"
                    [delay]="500">
                    <i class="ki-duotone ki-user-square fs-2x">
                        <span class="path1"></span>
                        <span class="path2"></span>
                        <span class="path3"></span>
                    </i>
                </button>
            </li>
            <li *ngIf="!isCalendarHidden" class="nav-item-m-0">
                <button
                    (click)="viewModeChange.emit(3)"
                    [ngClass]="{ active: viewMode === 3 }"
                    class="btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary"
                    tooltip="Calendar"
                    [delay]="500">
                    <i class="bi bi-calendar"></i>
                </button>
            </li>
        </ul>
        <!--end::Tab nav-->
        <!--begin::Actions-->
        <div class="d-flex my-0">
            <!--begin::Select-->
            <select
                name="pageSize"
                class="form-select form-select-sm form-select-solid w-150px me-0"
                [ngModel]="pageSize"
                (ngModelChange)="changePageSize($event)">
                <option value="10">10 items</option>
                <option value="20">20 items</option>
                <option value="50">50 items</option>
                <option value="100">100 items</option>
            </select>
            <!--end::Select-->
        </div>
        <!--end::Actions-->
    </div>
    <!--end::Controls-->
</div>
<!--end::Toolbar-->
