import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    signal,
    SimpleChanges
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject, takeUntil } from 'rxjs';
import { SubscriptionStatus } from '../../auth/auth.models';
import * as fromRoot from '../../reducers';
import { ChatHelper } from './chat-helper';
import * as uiActions from '../../ui/ui.actions';
import { Params } from '@angular/router';

@Component({
    selector: 'dir-txt',
    templateUrl: './text.component.html',
    styleUrls: ['./text.component.scss'],
    host: {
        //'[class.clipboard-enabled]': 'clipboardEnabled',
        '[class.py-2]': 'clipboardEnabled'
    },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextComponent implements OnInit, OnDestroy, OnChanges {
    private unsubscribe$ = new Subject<void>();
    @Input() text: string;
    @Input() url = '';
    @Input() routerLinkUrl = '';
    @Input() queryParams?: Params = null;
    @Input() allowPosthog = false;
    @Input() clipboardEnabled = false;

    isBlurred = false;
    isTeams = false;
    subscriptionStatus: SubscriptionStatus = SubscriptionStatus.Subscribed;
    subscriptionStatus$: Observable<SubscriptionStatus>;
    chatHelper: ChatHelper;
    copied = signal(false);

    //Computed properties
    isValidEmail = false;
    isValidUrl = false;
    fieldTypeValue = '';
    readableEmail = '';

    private timeoutId = 0;
    constructor(private store: Store) {}

    ngOnInit(): void {
        this.chatHelper = new ChatHelper(this.text, this.isTeams);
        this.subscriptionStatus$ = this.store.pipe(select(fromRoot.selectSubscriptionStatus));
        const isDemo$ = this.store.pipe(select(fromRoot.selectIsDemo));
        const isTeams$ = this.store.pipe(select(fromRoot.selectIsTeams));
        combineLatest([this.subscriptionStatus$, isDemo$, isTeams$])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(([status, isDemo, isTeams]) => {
                this.subscriptionStatus = status;
                this.isTeams = isTeams;
                this.chatHelper.isTeams = isTeams;
                this.isBlurred =
                    !isDemo && (status === SubscriptionStatus.Expired || status === SubscriptionStatus.Lapsed);
            });
        this.computeValues();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.computeValues();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    onComposeMail($event: MouseEvent | null): boolean {
        $event?.stopPropagation();
        if (this.chatHelper.onComposeMail()) {
            $event?.preventDefault();
            return false;
        }
        //Return true to allow the default click action
        return true;
    }

    onChat($event: MouseEvent): boolean {
        const url = this.chatHelper.onChat();
        if (url.length == 0) {
            $event.preventDefault();
            $event.stopPropagation();
            return false;
        } else {
            this.store.dispatch(uiActions.openDeepLink({ url }));
        }
        return true;
    }

    canChat(): boolean {
        return this.chatHelper.canChat();
    }

    private computeValues() {
        this.isValidEmail = this.checkEmail();
        this.isValidUrl = this.checkUrl();
        this.fieldTypeValue = this.calculateFieldType();
        if (this.isValidEmail) {
            //insert <wbr> tags before the @ and the .
            //make sure the text can be rendered as html by angular
            //console.warn('Email: ' + this.text);
            this.readableEmail = this.text.replace(/@/g, '<wbr>@').replace(/\./g, '<wbr>.');
            //console.warn('Email: ' + this.readableEmail);
        }
    }

    private checkEmail(): boolean {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(this.text);
    }

    private checkUrl(): boolean {
        const urlRegex = /^https:\/\/[^ "]+$/;
        return this.url !== '' || this.routerLinkUrl !== '' || this.queryParams !== null || urlRegex.test(this.text);
    }

    onClick(event: MouseEvent) {
        //event.preventDefault();
        event.stopPropagation();
    }

    onCopyClick(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.copied.set(true);
        this.resetCopied();
    }

    private resetCopied() {
        if (this.timeoutId) {
            window.clearTimeout(this.timeoutId);
        }
        this.timeoutId = window.setTimeout(() => {
            this.copied.set(false);
        }, 1000);
    }

    private calculateFieldType(): string {
        if (!this.text) {
            return '';
        }
        if (this.checkEmail()) {
            return 'mail';
        }
        if (this.checkUrl()) {
            return 'url';
        }
        return '';
    }
}
