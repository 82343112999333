import { Component, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { Observable, Subject, combineLatest, takeUntil } from 'rxjs';
import * as dataActions from '../../data/data.actions';

@Component({
    selector: 'dir-alphabetic-filter',
    templateUrl: './alphabetic-filter.component.html',
    styleUrl: './alphabetic-filter.component.scss'
})
export class AlphabeticFilterComponent implements OnDestroy {
    letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
    inactiveLetters: string[] = [];
    filterLetter$: Observable<string>;
    filterLetter: string | null = null;
    isTouching = false;
    preventClick = false;
    unsubscribe$ = new Subject<void>();

    constructor(private store: Store) {
        this.filterLetter$ = this.store.pipe(select(fromRoot.selectFilterLetter));
        this.filterLetter$.pipe(takeUntil(this.unsubscribe$)).subscribe((filterLetter) => {
            this.filterLetter = filterLetter;
        });
        //calculate inactive letters based on selectActiveSetOfUsers and selectFilterLetter using combineLatest
        combineLatest([this.store.pipe(select(fromRoot.selectActiveSetOfUsers)), this.filterLetter$])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(([users, filterLetter]) => {
                if (filterLetter !== null) {
                    return;
                }
                this.inactiveLetters = this.letters.filter((letter) => {
                    return !users.some((user) => user.lastName?.toUpperCase().startsWith(letter.toUpperCase()));
                });
            });
    }

    onClick($event: MouseEvent, filterLetter: string) {
        console.log(filterLetter);
        $event.preventDefault();
        if (this.preventClick) {
            this.preventClick = false;
            return false;
        }
        this.toggleFilter(filterLetter);
        return false;
    }

    onTouchStart(event: TouchEvent) {
        this.isTouching = true;
        this.handleTouch(event);
    }

    onTouchMove(event: TouchEvent) {
        if (this.isTouching) {
            this.handleTouch(event);
        }
    }

    onTouchEnd() {
        this.isTouching = false;
        this.preventClick = true;
        setTimeout(() => (this.preventClick = false), 300);
    }

    // private handleTouch(event: TouchEvent) {
    //     const touch = event.touches[0];
    //     const targetElement = document.elementFromPoint(touch.clientX, touch.clientY);
    //     if (targetElement && targetElement.textContent) {
    //         const letter = targetElement.textContent.trim();
    //         if (this.letters.includes(letter)) {
    //             this.setFilter(letter);
    //         }
    //     }
    // }

    private handleTouch(event: TouchEvent) {
        const touch = event.touches[0];
        const targetElement = document.elementFromPoint(touch.clientX, touch.clientY);
        if (targetElement && targetElement.tagName === 'A') {
            const letter = targetElement.textContent?.trim();
            if (letter && this.letters.includes(letter)) {
                this.toggleFilter(letter);
            }
        }
    }

    private toggleFilter(filterLetter: string) {
        if (this.filterLetter === filterLetter) {
            this.setFilter(null);
        } else {
            this.setFilter(filterLetter);
        }
    }

    private setFilter(filterLetter: string | null) {
        //this.filterLetter = letter;
        //this.filterChanged.emit(this.activeFilter);
        this.store.dispatch(dataActions.setFilterLetter({ filterLetter: filterLetter }));
    }

    ngOnDestroy(): void {
        this.store.dispatch(dataActions.setFilterLetter({ filterLetter: null }));
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
