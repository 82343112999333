<div class="alphabet-filter-container p-0 p-lg-2 sticky-top">
    <ul class="list-unstyled alphabet-filter" (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)"
        (touchend)="onTouchEnd()">
        <li>
            <a href="#" (click)="onClick($event, null)" [ngClass]="{'fw-bold': (filterLetter$ | async) === null}">
                All
            </a>
        </li>
        <li *ngFor="let letter of letters">
            <a href="#" (click)="onClick($event, letter)"
                [ngClass]="{'fw-bolder': (filterLetter$ | async) === letter, 'inactive': inactiveLetters.indexOf(letter) !== -1}">
                {{ letter }}
            </a>
        </li>
    </ul>
</div>
