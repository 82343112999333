<div *ngIf="mail || userId !== ''" class="mt-2">
    <a *ngIf="userId && userId !== ''" routerLink="/profile/{{userId}}" class="text-hover-primary" (click)="goProfile($event)">
        <i class="bi bi-person px-1" [ngClass]="{'fs-3': size === 'sm', 'fs-2tx me-2': size === 'md', 'fs-3hx me-2': size === 'lg'}"></i>
    </a>
    <a *ngIf="isValidEmail()" href="mailto:{{mail}}" (click)="onComposeMail($event)" class="text-hover-primary">
        <i class="bi bi-envelope px-1" [ngClass]="{'fs-3': size === 'sm', 'fs-2tx me-3': size === 'md', 'fs-3hx me-2': size === 'lg'}"></i>
    </a>
    <a *ngIf="!isOutlook && isValidEmail()" href="#" (click)="onChat($event)" class="text-hover-primary">
        <i class="bi bi-chat-left-text px-1" [ngClass]="{'fs-4': size === 'sm', 'fs-2qx me-3': size === 'md', 'fs-3x me-2': size === 'lg'}"></i>
    </a>
    <a *ngIf="isValidEmail()" href="#" (click)="onCall($event)" class="text-hover-primary">
        <i class="bi bi-telephone" [ngClass]="{'fs-4 ps-2': size === 'sm', 'fs-2qx ps-1': size === 'md', 'fs-3x ps-1': size === 'lg'}"></i>
    </a>
</div>
