import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersComponent } from './components/users.component';
import { UsersCardsComponent } from './components/users-cards.component';
import { UsersTableComponent } from './components/users-table.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { ToolbarComponent } from './components/toolbar.component';
import { AlphabeticFilterComponent } from './components/alphabetic-filter.component';
import { SelectedUsersComponent } from './components/selected-users.component';
import { TextComponent } from './components/text.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CalendarComponent } from './components/calendar/calendar.component';
import { ChatComponent } from './components/chat.component';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { FullCalendarModule } from '@fullcalendar/angular';
import { ClipboardModule } from '@angular/cdk/clipboard';

@NgModule({
    declarations: [
        AlphabeticFilterComponent,
        CalendarComponent,
        ChatComponent,
        ToolbarComponent,
        TextComponent,
        SelectedUsersComponent,
        UsersComponent,
        UsersCardsComponent,
        UsersTableComponent
    ],
    exports: [ChatComponent, TextComponent, UsersComponent, UsersCardsComponent, UsersTableComponent],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        SharedModule,
        PaginationModule.forRoot(),
        TooltipModule.forRoot(),
        ClipboardModule,
        MatMenuModule,
        FullCalendarModule
    ]
})
export class UsersModule {}
