import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject, takeUntil } from 'rxjs';
import { SubscriptionStatus } from '../../auth/auth.models';
import * as fromRoot from '../../reducers';
import { ChatHelper } from './chat-helper';
import * as uiActions from '../../ui/ui.actions';
@Component({
    selector: 'dir-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    @Input() mail: string;
    @Input() userId = '';
    @Input() size: 'sm' | 'md' | 'lg' = 'sm';
    @Output() profileClick = new EventEmitter();
    isEnabled = false;
    isTeams = false;
    subscriptionStatus: SubscriptionStatus = SubscriptionStatus.Subscribed;
    subscriptionStatus$: Observable<SubscriptionStatus>;
    isOutlook = false;
    chatHelper: ChatHelper;
    constructor(private store: Store) {}

    ngOnInit(): void {
        this.subscriptionStatus$ = this.store.pipe(select(fromRoot.selectSubscriptionStatus));
        const isDemo$ = this.store.pipe(select(fromRoot.selectIsDemo));
        const isTeams$ = this.store.pipe(select(fromRoot.selectIsTeams));
        const teamsEnvironment$ = this.store.pipe(select(fromRoot.selectTeamsEnvironment));

        this.chatHelper = new ChatHelper(this.mail, this.isTeams);

        combineLatest([this.subscriptionStatus$, isDemo$, isTeams$, teamsEnvironment$])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(([status, isDemo, isTeams, teamsEnv]) => {
                this.subscriptionStatus = status;
                this.isTeams = isTeams;
                this.chatHelper.isTeams = isTeams;
                this.isOutlook = teamsEnv?.isOutlook;
                this.isEnabled =
                    !isDemo && !(status === SubscriptionStatus.Expired || status === SubscriptionStatus.Lapsed);
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    onComposeMail($event: MouseEvent): boolean {
        if (this.chatHelper.onComposeMail()) {
            $event.preventDefault();
            $event.stopPropagation();
            return false;
        } else {
            $event.stopPropagation();
            return true;
        }
    }

    onChat($event: MouseEvent): boolean {
        $event.preventDefault();
        $event.stopPropagation();
        const url = this.chatHelper.onChat();
        if (url && url.length > 0) {
            this.store.dispatch(uiActions.openDeepLink({ url }));
        }
        return false;
    }

    onCall($event: MouseEvent): boolean {
        $event.preventDefault();
        $event.stopPropagation();
        const url = this.chatHelper.onCall([this.mail]);
        if (url && url.length > 0) {
            this.store.dispatch(uiActions.openDeepLink({ url }));
        }
        return false;
    }


    canChat(): boolean {
        return this.chatHelper.canChat();
    }

    isValidEmail(): boolean {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return this.mail && emailRegex.test(this.mail);
    }

    goProfile($event: MouseEvent) {
        //$event.preventDefault();
        $event.stopPropagation();
        this.profileClick.emit();
        return true;
    }
}
