<div class="card card-flush">
    <div class="card-body pt-0">
        <div *ngIf="userColumnSettings" class="table-responsive">
            <table class="table table-row-bordered table-row-dashed table-hover gy-4 align-middle fw-bolder no-footer">
                <thead class="fs-7 text-gray-400 text-uppercase">
                    <tr>
                        <th>
                            <div class="form-check form-check-sm form-check-custom form-check-solid me-3 ps-2">
                                <input class="form-check-input" type="checkbox" value="1" (click)="onSelectAll($event)"
                                    [checked]="areAllSelected">
                            </div>
                        </th>
                        <th class="px-0"></th>
                        <th class="min-w-250px sorting">Name</th>
                        <th class="min-w-250px sorting" *ngFor="let colDef of userColumnSettings.table">
                            {{colDef.name}}
                        </th>
                    </tr>
                </thead>
                <tbody class="fs-6">
                    <tr *ngFor="let user of users" [routerLink]="[]" [queryParams]="{ userId: user.id }">
                        <td class="align-top" (click)="$event.stopPropagation()">
                            <div class="form-check form-check-sm form-check-custom form-check-solid mt-4 ps-2">
                                <input class="form-check-input" type="checkbox" value="1"
                                    (click)="onSelect($event, user)" [checked]="isChecked(user)">
                            </div>
                        </td>
                        <td class="px-0">
                            <dir-graph-picture *ngIf="dataSourceType === 2" [directoryUser]="user" [size]="50">
                            </dir-graph-picture>
                            <div *ngIf="dataSourceType === 1" class="symbol symbol-35px symbol-circle">
                                <img [src]="user.pictureUrl" alt="user picture">
                            </div>
                        </td>
                        <td class="align-top">
                            <div class="d-flex align-items-center flex-grow-1">
                                <div class="d-flex flex-column justify-content-center">
                                    <div class="d-flex flex-row align-items-center">
                                        <a [routerLink]="[]" [queryParams]="{ userId: user.id }"
                                            class="mb-1 text-gray-800 text-hover-primary">
                                            <dir-user-presence *ngIf="presenceFeatureConsented" [id]="user.id"
                                                mode="square"></dir-user-presence>
                                            <dir-txt [text]="user.displayName"></dir-txt>
                                        </a>
                                        <a [routerLink]="[]" class="text-hover-primary text-muted ms-1"
                                            (click)="toggleFavorite($event, user)">
                                            <i class="ki-star fs-3"
                                                [ngClass]="{'ki-duotone': isFavorite(user), 'ki-outline': !isFavorite(user)}"></i>
                                        </a>
                                    </div>
                                    <div class="fw-bold fs-6 text-gray-400">
                                        <dir-txt [text]="user.jobTitle"></dir-txt>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td *ngFor="let colDef of userColumnSettings.table" class="align-top">
                            <dir-txt [text]="user[colDef.originalName]" queryParamName="userId"
                                [queryParams]="colDef.originalName === 'managerDisplayName' ? { userId: user.managerId } : null"></dir-txt>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
